import { ApiService } from '../../services/api.service';
import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

const ACCEPT_ATRIBUTE_MAP = {
	csv: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
};

@Component({
	selector: 'app-upload-button',
	templateUrl: './upload-button.component.html',
	styleUrls: ['./upload-button.component.scss']
})
export class UploadButtonComponent {
	@Input() container: string;
	@Input() fileName: string;
	@Input() params: any;
	@Input() watchText: any;
	@Output() uploadSuccess = new EventEmitter();
	@ViewChild('fileInput') fileInput: ElementRef;
	_accept: string;
	constructor(private api: ApiService) { }

	@Input()
	set accept(key: string) {
		this._accept = ACCEPT_ATRIBUTE_MAP[key] || '*';
	}

	get accept(): string {
		return this._accept;
	}

	upload(event) {

		if (event.target.files.length > 0) {

			const file = event.target.files[0];

			const uploadParams = {
				container: this.container,
				file,
				groupName: 'group test',
				validUntil: '01/01/2020',
				insuranceCompany: '1',
				policyNumber: '987654321'

			};

			this.fileName = file.name;

			this.api
				.uploadGroups(uploadParams)
				.subscribe(this.handleUploadSuccess.bind(this));

		}

	}

	download() {
		const url = this.api.downloadGroups({ fileName: this.fileName });
		window.open(url);
	}

	clear() {
		this.fileName = '';
		this.fileInput.nativeElement.value = '';
	}

	handleUploadSuccess(response) {
		const fileResult = response.result.files.file[0];
		const fileName = (fileResult && fileResult.name) || '';
		this.uploadSuccess.emit(fileName);
	}
	
}
