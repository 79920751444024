import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SidebarModule } from 'ng-sidebar';
import { ApiService } from './services/api.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { Page404Component } from './components/page404/page404.component';
import { RewardsProgramComponent } from './components/rewards-program/rewards-program.component';
import { ManageGroupsComponent } from './components/manage-groups/manage-groups.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TableRewardsProgramComponent } from './components/table-rewards-program/table-rewards-program.component';
import { TableManageGroupsComponent } from './components/table-manage-groups/table-manage-groups.component';
import { ModalAddToGroupComponent } from './components/modal-add-to-group/modal-add-to-group.component';
import { ModalNewGroupComponent } from './components/modal-new-group/modal-new-group.component';
import { UploadButtonComponent } from './components/upload-button/upload-button.component';
import { ModalShowGroupComponent } from './modal-show-group/modal-show-group.component';

import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';

import { SearchPipe } from './pipe/search.pipe';
import { Page403Component } from './page403/page403.component';
import { HomePageComponent } from './home-page/home-page.component';

@NgModule({
	declarations: [
		AppComponent,
		Page404Component,
		RewardsProgramComponent,
		ManageGroupsComponent,
		SidebarComponent,
		TableRewardsProgramComponent,
		TableManageGroupsComponent,
		ModalAddToGroupComponent,
		ModalNewGroupComponent,
		UploadButtonComponent,
		ModalShowGroupComponent,
		SearchPipe,
		Page403Component,
		HomePageComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		NgbModule,
		ReactiveFormsModule,
		FormsModule,
		NgxSpinnerModule,
		BrowserAnimationsModule, // required animations module
		ToastrModule.forRoot(), // ToastrModule added
		SidebarModule.forRoot()
	],
	providers: [ApiService],
	bootstrap: [AppComponent]
})
export class AppModule { }
