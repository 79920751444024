import { map } from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { debounceTime } from 'rxjs/operators';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  resolve(path, obj) {
    return path.split('.').reduce(function (prev, curr) {
      return prev ? prev[curr] : null
    }, obj || self)
  }


  /**
   * 
   * @param value Array data
   * @param args {'text':string, 'field':string}
   */
  transform(value: any, args?: any): any {

    if (!value) {
      return value
    }

    if (!args.text) {
      return value
    }
    
    debounceTime(500)

    let newarray = value.filter((data) => {

      for (let f of args.fields) {
        let text = this.resolve(f, data)

        if (text){
          text = text.toUpperCase()
        }else{
          text = ''
        }

        args.text = args.text.toUpperCase()
        if (text.indexOf(args.text) > -1) {
          return true
        }
      }

      return false

    })

    return newarray;
  }

}
