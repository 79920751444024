import { Router } from '@angular/router';
import { environment } from './../../environments/environment';
import { AuthService } from './../services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  logged = false;
  loginUrl = environment.corporateUrl + environment.dashboardPath

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
    if (this.authService.getToken()) {
      this.logged = true

      setTimeout(() => {
        this.router.navigate(['/rewards-program'])
      }, 250);

    } else {
      setTimeout(() => {
        this.authService.logoutUser()
      }, 250);
    }
  }

}
