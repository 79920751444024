import { Component, OnInit } from '@angular/core';

import { ApiService } from 'src/app/services/api.service';
import { InsuredInterface } from '../../models/insured-interface';
import { GroupInterface } from '../../models/group-interface';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-rewards-program',
	templateUrl: './rewards-program.component.html',
	styleUrls: ['./rewards-program.component.scss']
})
export class RewardsProgramComponent implements OnInit {

	constructor(
		private api: ApiService,
		private spinner: NgxSpinnerService,
		private toastr: ToastrService
	) { }

	insureds = [];
	private groups: GroupInterface;
	loadingFile = false;
	searchText = '';
	showModalShow = false;
	selectedAll = false;

	ngOnInit() {
		setTimeout(() => {
			this.getInsureds();
		}, 250);
	}

	getInsureds() {
		this.spinner.show()

		this.api.getInsuredsAndGroup()
			.subscribe((insureds: []) => {
				this.insureds = insureds;
				this.spinner.hide()
			}, err => {
				this.spinner.hide()
			})
	}

	getGroups() {
		this.api.getGroupsFilter()
			.subscribe((groups: GroupInterface) => {
				this.groups = groups;
				console.log(this.groups);
			})
	}

	handleChangeFileInput(target) {

		let files = target.files

		if (files.length > 0) {
			debugger
			this.loadingFile = true
			let file = files[0];
			const params = new FormData();

			this.spinner.show()

			params.append('file', file);
			this.api.post('Storages/insureds/upload', params).subscribe((data) => {
				console.log(data)
				this.loadingFile = false
				this.spinner.hide()
				this.getInsureds()
				this.toastr.success('Insureds successfully added', 'All right!');
				target.value = ""
			}, (err) => {
				this.loadingFile = false
				this.spinner.hide()
				target.value = ""
				this.toastr.error('An error has occurred. ' + err.messege, 'Attention!');
			})
		}
	}

	selectAll() {
		this.insureds.map((val) => {
			val.checked = this.selectedAll
		})
	}

	selectGroup(select) {
		if (!select) {
			this.selectedAll = false
		} else {
			for (let insured of this.insureds) {
				if (!insured.checked) {
					return
				}
			}
			this.selectedAll = true
		}
	}

	deleteMasive() {

		if (this.insureds.length == 0) {
			this.toastr.info('Select at least one row', 'Attention!');
			return
		}

		let where = []

		for (let insured of this.insureds) {
			if (insured.checked) {
				where.push({
					id: insured.id
				})

			}
		}

		if (where.length == 0) {
			this.toastr.info('Select at least one row', 'Attention!');
			return
		}

		let msg = 'Are you sure to delete the selected insureds?'

		if (where.length == 0) {
			msg = 'Are you sure to delete all the insureds?'
		}

		if (!confirm(msg)) {
			return
		}

		this.spinner.show()

		this.api.deleteMasive('insureds', where).subscribe((data) => {
			this.getInsureds()
			this.spinner.hide()
			this.toastr.success('Insureds successfully deleted', 'All right!');
		}, err => {
			this.spinner.hide()
			this.toastr.error('An error has occurred', 'Attention!');
		})
	}


	downloadMasive() {

		if (this.insureds.length == 0) {
			return
		}

		let where = []

		if (!this.selectedAll) {
			for (let insured of this.insureds) {
				if (insured.checked) {
					where.push({
						id: insured.id
					})

				}
			}
		}

		let url = this.api.downloadXLSMasive('insureds/download?insuredIds=', where)

		window.open(url)
	}


}