import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import { isNullOrUndefined } from "util";
import { UserInterface } from '../models/user-interface';
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	constructor(private http: HttpClient) { }

	headers: HttpHeaders = new HttpHeaders({
		"Content-Type": "application/json"
	});

	setToken(token): void {
		localStorage.setItem("accessToken", token);
	}

	getToken() {
		return localStorage.getItem("accessToken");
	}

	getCurrentUser(): UserInterface {

		let user_string = localStorage.getItem("currentUser");

		if (isNullOrUndefined(user_string)) {
			let user: UserInterface = JSON.parse(user_string);
			return user;
		}
		else {
			return null;
		}

	}

	logoutUser() {
		localStorage.removeItem("accessToken");
		window.location.href = environment.corporateUrl + environment.dashboardPath + '?logout=true'
	}

}