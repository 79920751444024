import { NgxSpinnerService } from 'ngx-spinner';
import { GroupInterface } from './../../models/group-interface';
import { CompanyInterface } from './../../models/company-interface';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-modal-new-group',
	templateUrl: './modal-new-group.component.html',
	styleUrls: ['./modal-new-group.component.scss']
})
export class ModalNewGroupComponent implements OnInit {

	@ViewChild('btnClose') btnClose: ElementRef


	@Output() saved = new EventEmitter();


	@Input()
	group: GroupInterface

	action = 'New';

	groupForm: FormGroup;
	loading = false;
	submitted = false;
	fileUpload = undefined;

	constructor(
		private apiService: ApiService,
		private formBuilder: FormBuilder,
		private spinner: NgxSpinnerService,
		private toastr: ToastrService
	) { }

	companyForm: FormGroup;
	companies: CompanyInterface[];

	ngOnInit() {

		this.apiService.getCompanies().subscribe(
			(companies: CompanyInterface[]) => {
				this.companies = companies
			}
		)

		this.groupForm = this.formBuilder.group({
			groupName: ['', Validators.required],
			validUntil: ['', Validators.required],
			policyNumber: ['', Validators.required],
			insuranceCompany: ['', Validators.required]
		});

	}

	handleChangeFileInput(files) {

		if (files.length > 0) {
			this.fileUpload = files[0];
		} else {
			this.fileUpload = undefined
		}

	}

	get f() { return this.groupForm.controls; }

	onSubmit() {

		this.submitted = true;

		// stop here if form is invalid
		if (this.groupForm.invalid) {
			return;
		}

		if (!this.fileUpload) {
			return;
		}

		this.loading = true

		const params = new FormData();

		this.spinner.show()

		params.append('file', this.fileUpload);
		params.append('groupName', this.groupForm.get('groupName').value);
		params.append('validUntil', this.groupForm.get('validUntil').value);
		params.append('insuranceCompany', this.groupForm.get('insuranceCompany').value);
		params.append('policyNumber', this.groupForm.get('policyNumber').value);

		this.apiService.post('Storages/groups/upload', params).subscribe((data) => {
			console.log(data)
			this.loading = false
			this.saved.emit(true)
			this.spinner.hide()
			this.btnClose.nativeElement.click();
			this.groupForm.reset()
			this.toastr.success('Group successfully added', 'All right!');
			this.submitted = false;
			this.fileUpload = undefined
		}, err => {
			this.spinner.hide()
			this.submitted = false;
			this.loading = false
			this.btnClose.nativeElement.click();
			this.saved.emit(true)
			this.fileUpload = undefined
			this.toastr.error('An error has occurred', 'Attention!');
		})
	}

}
