import { AuthService } from './services/auth.service';
import { ApiService } from 'src/app/services/api.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if ('null' === this.authService.getToken() || this.authService.getToken() == null) {
      console.log('false')
      this.router.navigate(['unauthorized'])
      return false;
    }

    console.log('true')
    return true;
  }
}