import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { GroupInterface } from '../../models/group-interface';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-manage-groups',
	templateUrl: './manage-groups.component.html',
	styleUrls: ['./manage-groups.component.scss']
})
export class ManageGroupsComponent implements OnInit {

	constructor(
		private api: ApiService,
		private spinner: NgxSpinnerService,
		private toastr: ToastrService
	) { }

	public groups = [];
	public groupToShow: any;
	showModalShow = false;
	selectedAll = false;
	searchText = '';
	index;

	ngOnInit() {
		this.getGroups();
	}

	activate(group) {


		if (group.status) {
			this.api.patch('groups', {
				id: group.id,
				status: false
			}).subscribe((data) => {
				group.status = false
				this.toastr.success('The group has been successfully deactivated', 'All right!');
			})
		} else {
			this.api.patch('groups', {
				id: group.id,
				status: true
			}).subscribe((data) => {
				group.status = true
				this.toastr.success('The group has been successfully activated', 'All right!');
			})
		}
	}

	delete(group, index) {

		if (!confirm(`Are you sure to delete the group ${group.name}?`)) {
			return
		}

		this.spinner.show()

		this.api.delete('groups', group.id).subscribe((data) => {
			this.spinner.hide()
			this.groups.splice(index, 1)
			this.toastr.success('The group has been successfully deleted', 'All right!');
		}, err => {
			this.toastr.error('An error has occurred', 'Attention!');
		})
	}

	getGroups() {
		this.api.getGroupsAndCompanies()
			.subscribe((groups: []) => {
				this.groups = groups;
				console.log(this.groups);
			})
	}

	viewGroup(group, index) {
		this.index = index
		// console.log(group)
		this.groupToShow = group
		this.showModalShow = true
	}

	selectAll() {
		this.groups.map((val) => {
			val.checked = this.selectedAll
		})
	}

	selectGroup(select) {
		if (!select) {
			this.selectedAll = false
		} else {
			for (let group of this.groups) {
				if (!group.checked) {
					return
				}
			}

			this.selectedAll = true
		}
	}

	deleteMasive() {

		if (this.groups.length == 0) {
			this.toastr.info('Select at least one row', 'Attention!');
			return
		}

		let where = []

		for (let group of this.groups) {
			if (group.checked) {
				where.push({
					id: group.id
				})

			}
		}

		if (where.length == 0) {
			this.toastr.info('Select at least one row', 'Attention!');
			return
		}

		let msg = 'Are you sure to delete the selected groups?'

		if (where.length == 0) {
			msg = 'Are you sure to delete all the groups?'
		}

		if (!confirm(msg)) {
			return
		}

		this.spinner.show()

		this.api.deleteMasive('groups', where).subscribe((data) => {
			this.getGroups()
			this.spinner.hide()
			this.toastr.success('Groups successfully deleted', 'All right!');
		}, err => {
			this.spinner.hide()
			this.toastr.error('An error has occurred', 'Attention!');
		})
	}

	downloadMasive() {

		if (this.groups.length == 0) {
			return
		}

		let where = []

		if (!this.selectedAll) {
			for (let insured of this.groups) {
				if (insured.checked) {
					where.push({
						id: insured.id
					})

				}
			}
		}

		console.log(where)

		let url = this.api.downloadXLSMasive('groups/download?id=', where)

		window.open(url)
	}

}
