import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { GroupInterface } from 'src/app/models/group-interface';

@Component({
	selector: 'app-modal-add-to-group',
	templateUrl: './modal-add-to-group.component.html',
	styleUrls: ['./modal-add-to-group.component.scss']
})
export class ModalAddToGroupComponent implements OnInit {

	 groups: GroupInterface;

	constructor(private api: ApiService) { }

	ngOnInit() {
		this.getGroups();
	}

	getGroups() {
		this.api.getGroups()
			.subscribe((groups: GroupInterface) => {
				this.groups = groups;
				// console.log(this.groups);
			})
	}

}
