import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-rewards-program',
  templateUrl: './table-rewards-program.component.html',
  styleUrls: ['./table-rewards-program.component.scss']
})
export class TableRewardsProgramComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
