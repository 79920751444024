import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {


  dashboardUrl = environment.corporateUrl + environment.dashboardPath
  medicusCertaUrl = environment.medicusCertaUrl

  logged = false;

  constructor(private authService: AuthService) {
    if (this.authService.getToken()) {
      this.logged = true
    }
  }

  ngOnInit() {
    $("#offcanvas").offcanvas();
  }  

  toggle() {
    $('#offcanvas').offcanvas("toggle");
  }

  handleLogout() {
    this.authService.logoutUser()
  }


}
