import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from './services/api.service';
import { AuthService } from './services/auth.service';
import { Component, OnInit } from '@angular/core';

declare var jQuery: any;
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private apiService: ApiService,
  ) {

    this.route.queryParams.subscribe(params => {
      if (params['token']) {
        this.apiService.initSession(params['token']);
      }
    });

  }


  ngOnInit() {
    $("#offcanvas").offcanvas();
  }

  toggle() {
    $('#offcanvas').offcanvas("toggle");
  }

}
