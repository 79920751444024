import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { environment } from './../../environments/environment';
import { CompanyInterface } from '../models/company-interface';
import { GroupInterface } from '../models/group-interface';
import { InsuredInterface } from '../models/insured-interface';

const API_URL = environment.apiUrl;

@Injectable({
	providedIn: 'root'
})

export class ApiService {

	constructor(private http: HttpClient, private authService: AuthService) { }

	companies: Observable<any>;
	company: Observable<any>;

	groups: Observable<any>;
	group: Observable<any>;

	insureds: Observable<any>;
	insured: Observable<any>;

	headers: HttpHeaders = new HttpHeaders({
		'Content-Type': 'application/json',
		Authorization: this.authService.getToken()
	});

	httpOptions = {
		headers: this.headers
	};


	private get accessTokenAppend() {
		return this.accessToken ? `?access_token=${this.accessToken}` : '';
	}

	private get accessToken(): string {
		return localStorage.getItem('accessToken');
	}

	private set accessToken(token: string) {
		localStorage.setItem('accessToken', token);
	}

	public initSession(token: string) {
		this.accessToken = token;
	}

	//#region GETs

	getInsureds() {
		return this.http.get(`${API_URL}/insureds${this.accessTokenAppend}`);
	}

	getInsuredsAndGroup() {

		let filter = {
			// fields:'*'
			include: {
				relation: 'group',
				scope: {
					include: {
						relation: 'company',

					},
				}
			},
		}


		return this.http.get(`${API_URL}/insureds${this.accessTokenAppend}&filter=${JSON.stringify(filter)}`);
	}

	getGroups() {
		return this.http.get(`${API_URL}/groups${this.accessTokenAppend}`);
	}

	getGroupsAndCompanies() {

		let filter = {
			// fields:'*'
			include: {
				relation: 'company'
			},
		}

		return this.http.get(`${API_URL}/groups${this.accessTokenAppend}&filter=${JSON.stringify(filter)}`);
	}

	getCompanies() {

		return this.http.get(`${API_URL}/companies${this.accessTokenAppend}`);
	}

	downloadGroups({ fileName }) {
		return `${API_URL}/Storages/groups/download/${fileName}`;
	}

	downloadInsureds({ fileName }) {
		return `${API_URL}/Storages/insureds/download/${fileName}`;
	}

	//#endregion GETs

	//#region GETs by ID

	getInsuredById(id: string) {
		return (this.insured = this.http.get(`${API_URL}/insureds/${id}${this.accessTokenAppend}`));
	}

	getGroupById(id: string) {
		return (this.group = this.http.get(`${API_URL}/groups/${id}${this.accessTokenAppend}`));
	}

	getCompanyById(id: string) {
		return (this.group = this.http.get(`${API_URL}/companies/${id}${this.accessTokenAppend}`));
	}

	//#endregion GETs by ID

	//#region GETs with filter

	getGroupsFilter() {
		return (this.groups = this.http.get(`${API_URL}/groups${this.accessTokenAppend}&filter[include]=insureds&filter[include]=company`));
	}

	//#endregion GETs with filter

	//#region POST

	saveInsured(insured: InsuredInterface) {
		return this.http.post<InsuredInterface>(
			// `${API_URL}/insureds?token=${this.authService.getToken()}`
			`${API_URL}/insureds${this.accessTokenAppend}`
			, insured)
			.pipe(map(data => data));
	}

	uploadGroups(params) {

		const input = new FormData();

		input.append('file', params.file);
		input.append('groupName', params.groupName);
		input.append('validUntil', params.validUntil);
		input.append('insuranceCompany', params.insuranceCompany);
		input.append('policyNumber', params.policyNumber);

		let result = this.http.post(`${API_URL}/Storages/groups/upload${this.accessTokenAppend}`, input);

		return result;
	}

	uploadInsureds({ file }) {
		const input = new FormData();
		input.append('file', file);
		return this.http.post(`${API_URL}/Storages/insureds/upload${this.accessTokenAppend}`, input);
	}

	//#endregion POST

	//#region PUT

	updateInsured(insured: InsuredInterface) {
		return this.http.put<InsuredInterface>(
			// `${API_URL}/insureds?token=${this.authService.getToken()}`
			`${API_URL}/insureds${this.accessTokenAppend}`
			, insured
			, { headers: this.headers })
			.pipe(map(data => data));
	}

	//#endregion PUT

	//#region DELETE

	deleteInsured(id: string) {
		return this.http.delete<InsuredInterface>(
			// `${API_URL}/insureds/${id}?token=${this.authService.getToken()}`
			`${API_URL}/insureds${this.accessTokenAppend}`
			, { headers: this.headers })
			.pipe(map(data => data));
	}

	//#endregion DELETE

	// apiGeneric

	get(resource: string, id?: number): Observable<any> {

		let endpoint = resource
		if (id) {
			endpoint = resource + '/' + id
		}

		let reqOpts = this.httpOptions.headers

		let params = new HttpParams()

		return this.http.get(API_URL + '/' + endpoint + this.accessTokenAppend, { headers: reqOpts, params })
			.pipe(
				retry(1),
				catchError(this.handleError)
			)
	}

	getAll(resource: string, params?: any, auth: boolean = true): any {

		let search = new URLSearchParams();

		for (let k in params) {
			if (k == 'fields' || k == 'filters') {
				search.set(k, JSON.stringify(params[k]));
			} else {
				search.set(k, params[k]);
			}
		}

		return this.http.get(API_URL + '/' + resource + this.accessTokenAppend, params)
			.pipe(
				retry(1),
				catchError(this.handleError)
			)

	}


	/**
	 * 
	 * @param resource 
	 * @param reqParams i.e. body = { email: 'data@data.com' }
	 */
	post(resource: string, reqParams?: any) {

		let reqOpts = this.httpOptions.headers

		let params = new HttpParams()

		return this.http.post(API_URL + '/' + resource + this.accessTokenAppend, reqParams)
			.pipe(
				retry(1),
				catchError(this.handleError)
			)

	}


	put(resource: any, id?: number, reqParams?: any) {

		let reqOpts = this.httpOptions.headers

		let params = new HttpParams()

		if (id) {
			resource = resource + '/' + id
		}

		return this.http.put(API_URL + '/' + resource + this.accessTokenAppend, reqParams, { headers: reqOpts, params })
			.pipe(
				retry(1),
				catchError(this.handleError)
			)

	}


	patch(endpoint: string, data?: any) {

		return this.http.patch(API_URL + "/" + endpoint + this.accessTokenAppend, data)
			.pipe(
				retry(1),
				catchError(this.handleError)
			)
	}

	delete(resource: any, id: number) {
		return this.http.delete(API_URL + "/" + resource + '/' + id + this.accessTokenAppend)
			.pipe(
				retry(1),
				catchError(this.handleError)
			)
	}

	deleteMasive(resource: any, where?: Array<any>) {

		let filter = {}

		if (where.length > 0) {

			filter = {
				or: where
			}
		}

		return this.http.request('delete', API_URL + "/" + resource + this.accessTokenAppend, { body: 'where=' + encodeURI(JSON.stringify(filter)), headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) })
			.pipe(
				retry(1),
				catchError(this.handleError)
			)
	}

	downloadXLSMasive(resource: any, where?: Array<any>) {
		let params = where.length == 0 ? '' : encodeURI(JSON.stringify(where))
		return API_URL + "/" + resource + params
	}

	handleError(error) {
		let errorMessage = '';
		// debugger
		if (error.error instanceof ErrorEvent) {
			// Get client-side error
			errorMessage = error.error.message;
		} else {
			// Get server-side error
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
			return throwError(error);
		}
		// window.alert(errorMessage);
		console.error(errorMessage);
		return throwError(errorMessage);
	}

}