import { HomePageComponent } from './home-page/home-page.component';
import { Page403Component } from './page403/page403.component';
import { AuthGuard } from './auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RewardsProgramComponent } from './components/rewards-program/rewards-program.component';
import { Page404Component } from './components/page404/page404.component';
import { ManageGroupsComponent } from './components/manage-groups/manage-groups.component';

const routes: Routes = [

	{ path: '', component: HomePageComponent },
	{ path: 'rewards-program', component: RewardsProgramComponent, canActivate: [AuthGuard] },
	{ path: 'manage-groups', component: ManageGroupsComponent, canActivate: [AuthGuard] },

	{ path: 'unauthorized', component: Page403Component },
	{ path: '**', component: Page404Component } // always ended with this line


];

@NgModule({

	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]

})

export class AppRoutingModule { }
