import { ApiService } from './../services/api.service';
import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-modal-show-group',
  templateUrl: './modal-show-group.component.html',
  styleUrls: ['./modal-show-group.component.scss']
})
export class ModalShowGroupComponent implements OnChanges, OnInit {

  @ViewChild('btnClose') btnClose: ElementRef

  @Output()
  groupDelete = new EventEmitter();
  @Input()
  group: any;
  grupo: any;
  insureds: any;
  searchText = ''
  showModalShow = false;
  selectedAll = false;
  loadingFile = false;

  constructor(
    private api: ApiService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    console.log('ngOnInit', this.group)
  }

  ngOnChanges(changes: SimpleChanges) {

    const group: SimpleChange = changes.group;
    if (group.currentValue) {
      this.grupo = group.currentValue

      this.getInsureds(this.grupo.id)

    }
  }

  activate(group) {


    if (group.status) {
      this.api.patch('groups', {
        id: group.id,
        status: false
      }).subscribe((data) => {
        group.status = false
      })
    } else {
      this.api.patch('groups', {
        id: group.id,
        status: true
      }).subscribe((data) => {
        group.status = true
      })
    }
  }

  delete() {

    this.groupDelete.emit();

    this.btnClose.nativeElement.click();
  }

  getInsureds(groupId) {
    let filter = {
      // fields:'*'
      include: {
        relation: 'group',
        scope: {
          include: {
            relation: 'company',

          },
        }
      },
    }
    this.api.getAll(`groups/${groupId}/insureds?filter=${JSON.stringify(filter)}`).subscribe(
      (insureds: any) => {
        this.insureds = insureds
      }
    )
  }

  selectAll() {
    this.insureds.map((val) => {
      val.checked = this.selectedAll
    })
  }

  selectGroup(select) {
    if (!select) {
      this.selectedAll = false
    } else {
      for (let insured of this.insureds) {
        if (!insured.checked) {
          return
        }
      }
      this.selectedAll = true
    }
  }

  deleteMasive() {

    if (this.insureds.length == 0) {
      this.toastr.info('Select at least one row', 'Attention!');
      return
    }

    let where = []

    for (let insured of this.insureds) {
      if (insured.checked) {
        where.push({
          id: insured.id
        })

      }
    }

    if (where.length == 0) {
      this.toastr.info('Select at least one row', 'Attention!');
      return
    }

    let msg = 'Are you sure to delete the selected insureds?'

    if (where.length == 0) {
      msg = 'Are you sure to delete all the insureds?'
    }

    if (!confirm(msg)) {
      return
    }

    this.spinner.show()

    this.api.deleteMasive('insureds', where).subscribe((data) => {
      this.getInsureds(this.group.id)
      this.spinner.hide()
      this.toastr.success('Insureds successfully deleted', 'All right!');
    }, err => {
      this.spinner.hide()
      this.toastr.error('An error has occurred', 'Attention!');
    })
  }

  handleChangeFileInput(files) {
    if (files.length > 0) {
      this.loadingFile = true
      let file = files[0];
      const params = new FormData();

      this.spinner.show()

      params.append('file', file);
      params.append('groupId', this.group.id);
      this.api.post('Storages/groups/upload', params).subscribe((data) => {
        console.log(data)
        this.loadingFile = false
        this.spinner.hide()
        this.getInsureds(this.group.id)
      }, (err) => {
        this.loadingFile = false
        this.spinner.hide()
        alert('An error has occurred')
      })
    }
  }

  downloadMasive() {

    if (this.insureds.length == 0) {
      return
    }

    let where = []

    if (!this.selectedAll) {
      for (let insured of this.insureds) {
        if (insured.checked) {
          where.push({
            id: insured.id
          })

        }
      }
    }

    let resource = 'insureds/download?insuredIds='

    if (where.length == 0) {
      resource = `groups/${this.group.id}/download`
    }

    let url = this.api.downloadXLSMasive(resource, where)

    window.open(url)
  }

}
